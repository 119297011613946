export default {
  heading: {
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
    textTransform: 'capitalize'
  },
  title: {
    fontSize: ['1.75rem', '2rem', '2.25rem', '2.5rem', '2.75rem'],
    fontWeight: 'light',
    borderBottom: '1px solid',
    width: 'fit-content',
    pb: '1rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    textTransform: 'uppercase',
    fontFamily: 'heading'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'text2',
    fontFamily: 'subheading',
    opacity: '0.8',
    fontWeight: '200'
  },
  text: {
    fontWeight: 'body',
    fontSize: '1rem',
    lineHeight: '1.75',
    fontSize: ['1rem', '', '1.1rem'],
    padding: '0.25rem 0rem',
    '*': {
      color: 'inherit',
      textAlign: 'justify',
      fontSize: 'inherit'
    }
  },
  navLink: { fontFamily: 'heading' },
  link: {
    textDecoration: 'none',
    color: 'unset'
  }
}
