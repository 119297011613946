const customVariants = {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {
      height: 'auto',
      padding: '0rem',
      margin: '0rem auto 0rem 0rem',
      position: 'static'
    },
    '.logo': {
      padding: '0rem',
      backgroundColor: 'white',
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['65px', '75px', '90px', '100px'],
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      mr: 'auto',
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      padding: '0rem',
      display: ['flex', 'flex', 'flex', 'flex'],
      img: {
        maxHeight: ['65px', '75px', '90px', '100px'],
        filter: 'unset',
        padding: '0rem'
      }
    },
    '.container': {
      padding: ['1rem 1rem 1rem', '1rem 2rem', '3rem 2rem 1rem', '3rem 2rem 1rem'],
      position: 'fixed',
      backgroundColor: 'white',
      top: '0rem'
    },
    '.containerScrolled': {
      padding: ['1rem 1rem 1rem', '1rem 2rem', '3rem 2rem 1rem', '3rem 2rem 1rem'],
      backgroundColor: 'background',
      position: 'fixed',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'primary'
      // '.phoneContainer': {
      //   display: 'none'
      // }
    },
    '.hamburger': {
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'primary',
      backgroundColor: 'rgba(255,255,255, 0.6)',
      display: ['', '', '', 'none'],
      mr: '0rem',
      '> div': {
        backgroundColor: 'primary'
      }
    },
    '.smallNavMenu': {
      '.navItem': {
        borderColor: 'dark',
        p: '0.5rem',
        m: '0.25rem',
        a: { fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem', '0.9rem'] },
        color: 'primary',
        ':hover': {
          color: 'text'
        }
      },
      '.gallery': {
        display: 'none'
      },
      '.navItemDropdownMenu': {
        backgroundColor: 'white',
        padding: '1rem',
        width: 'fit-content',
        '.navItem': { p: '0.75rem 0.25rem' }
      },
      '.munival': {
        border: 'solid 1px',
        backgroundColor: 'primary',
        color: 'white',
        p: '0.5rem 0.75rem',
        ':hover': {
          color: 'text',
          borderColor: 'text',
          backgroundColor: 'transparent'
        }
      }
    },

    '.phoneContainer': {
      display: ['none', '', 'flex'],
      position: 'absolute',
      top: '0rem',
      margin: '0rem',
      left: '0rem',
      border: 'none',
      width: '100%',
      backgroundColor: 'primary',
      justifyContent: 'flex-end',
      padding: '0.75rem',
      a: {
        '::before': {
          content: "'Get In Touch With Us -'",
          marginRight: '0.25rem',
          fontSize: '0.9rem'
        },
        textAlign: 'center',
        color: 'light',
        fontSize: '0.9rem',
        svg: {
          display: 'none'
        },
        ':hover': {
          opacity: '0.7',
          color: 'light',
          fontSize: '0.9rem'
        }
      }
    },
    '.hamburgerOpen': {
      borderColor: 'white',
      '> div': {
        backgroundColor: 'light'
      }
    },
    '.navMenuLogo': {
      display: 'none'
    },
    '.navMenu': {
      transform: 'unset',
      top: '0rem',
      transition: 'all ease-in-out 0.5s',
      height: '100vh',
      left: '-200vw'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      textAlign: ['', '', '', 'right'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        a: {
          color: 'light',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          fontFamily: 'heading'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white',
        fontFamily: 'heading'
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      }
    },
    '.navBlock': {
      right: 'unset',
      top: '200vh'
    }
  },

  footer: {
    backgroundColor: 'primary',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    padding: ['2rem 0.5rem 6rem', '', '2rem 3rem'],
    '.image': {
      display: 'none'
    },
    '.multiButtonContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        width: 'fit-content',
        textTransform: 'uppercase',
        fontSize: '1.1rem',
        fontFamily: 'heading'
      }
    },
    '.contactDetails-container': {
      flexDirection: ['', '', '', 'row'],
      flexWrap: 'wrap',
      br: { display: 'none' },
      borderBottom: '1px solid',
      pb: '1rem',
      textAlign: ['center', '', '', 'center'],
      svg: {
        display: ['none', '', '', 'inline']
      }
    },
    '.copyright': {
      width: '50%',
      color: 'white',
      textAlign: 'center'
    },
    '.poweredByText': {
      margin: 'auto'
    },
    '.socialIconsContainer': {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '2rem',

      a: {
        padding: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ':hover': {
          backgroundColor: 'dark'
        },
        svg: {
          width: '28px',
          height: '28px'
        }
      }
    },
    '.gonationLogo': {
      width: '50%'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  sideBySide1: {
    padding: ['1rem', '', '25vh 3rem'],
    backgroundColor: 'background',
    margin: '0rem',
    '.lazyload-wrapper': {
      minHeight: '50vh',
      justifyContent: 'flex-start',
      width: ['', '', '60%', '60%']
    },
    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      width: ['', '', '40%', '40%'],
      justifyContent: 'flex-start'
    },
    '.title': {
      variant: 'text.title'
    },
    '.subtitle': {
      variant: 'text.subtitle'
    },
    '.text': {
      variant: 'text.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    width: '100%',
    justifyContent: 'flex-start',
    padding: ['0rem 1.5rem 2rem', '', '0rem 7.5vw 2rem'],
    position: 'relative',
    '.section': {
      width: ['fit-content', '', 'fit-content'],
      margin: '0rem',
      alignItems: 'flex-start',
      textAlign: 'left',
      padding: ['2rem', '', '3rem'],
      marginTop: '157px'
    },
    '.title': {
      variant: 'text.title',
      width: 'fit-content'
      // fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem']
    },
    '.subtitle': {
      variant: 'text.subtitle',
      width: '100%',
      justifyContent: 'center'
    },
    '.text': {
      variant: 'text.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },
  homepageShout: {
    backgroundColor: 'background',
    marginBottom: ['5rem', '', '0rem'],
    marginTop: '3rem',
    padding: ['1rem', '', '2rem 1rem', '3rem 2rem 6rem', '3rem 3rem 6rem'],
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    '.title': {
      variant: 'text.title',
      borderBottom: '3px solid',
      borderColor: 'text2',
      fontFamily: 'heading',
      width: ['', '', '70%'],
      backgroundColor: 'transparent',
      letterSpacing: '0px',
      color: 'text',
      fontSize: ['1rem', '1.1rem', '1.2rem', '1.3rem', '1.3rem']
    },
    '.text': {
      variant: 'text.text',
      fontFamily: 'heading',
      fontSize: ['1.25rem', '', ''],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      backgroundColor: ['backgroundSecondary'],
      textTransform: ['', '', ''],
      padding: ['2rem', '2rem'],
      marginTop: ['', '0rem', '', '3rem'],
      width: ['', '', '45%', '45%', '55%']
    },
    '.date': {
      backgroundColor: ['transparent', '', 'primary'],
      border: ['solid 1px', '', '', 'solid 0.5rem'],
      borderColor: ['dark', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'unset',
      color: ['dark', '', 'white'],
      flexGrow: '1',
      fontWeight: 'bold',
      justifyContent: 'center',
      position: 'relative',
      left: ['', '', '', '-3rem'],
      bottom: ['', '', '', '-2rem']
    },
    '.imageContainer': {
      position: 'relative',
      mt: ['0.5rem', '', '0rem', '', ''],
      top: ['', '', '', '2.5rem'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
      flexGrow: '1',
      '.imageFill': {
        paddingBottom: ['100%', '70%', '70%']
      }
    },
    '.shoutCTABtns ': {
      border: ['', '', '', 'solid 8px'],
      borderColor: ['', '', '', 'background'],
      flexDirection: ['row', 'row', 'row', 'column'],
      width: ['100%', '', '', 'auto'],
      order: ['6', '', '5', '5'],
      // marginLeft: ['0rem', '', '0rem'],
      a: {
        width: ['fit-content', '', '', 'fit-content'],
        flexGrow: 'unset',
        writingMode: ['unset', 'unset', 'unset', 'tb'],
        padding: ['1rem 3.5rem', '', '', '3.5rem 1rem'],
        backgroundColor: 'transparent',
        color: 'grey',
        borderColor: 'grey',
        fontFamily: 'sans-serif',
        fontWeight: 'normal',
        letterSpacing: '0px',
        ':hover': {
          backgroundColor: 'primary',
          color: 'white',
          borderColor: 'primary'
        }
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      width: ['', '', '40%'],
      '.image': {
        objectFit: 'cover',
        objectPosition: 'center'
      }
    },
    '.content': {
      width: ['', '', '60%'],
      padding: ['1rem', '2rem', '3rem', '4rem', '5rem']
    }
  },

  homepageBoxes: {
    backgroundColor: 'transparent',
    padding: ['0rem', '', '', '0rem'],
    '.box': {
      width: ['100%', '', '50%', '50%'],
      transition: 'all ease-in-out 1s',
      borderRadius: '0px',
      position: 'relative',
      color: 'white',
      padding: 'calc(5% + 1.5rem)',
      margin: ['1rem 1rem', '', '0rem'],
      ':hover': {
        '.image': {
          filter: 'unset'
        }
      },
      '.image': {
        position: 'absolute',
        top: '0%',
        left: '0%',
        height: '100%',
        width: '100%',
        filter: 'grayscale(70%)',
        objectFit: 'cover',
        transition: 'all ease-in-out 0.5s',
        zIndex: '-3'
      },
      '.title': {
        variant: 'text.title',
        transition: 'all ease-in-out 1s',
        mt: '1rem',
        marginBottom: '1rem',
        padding: '0rem',
        paddingBottom: '0.5rem',
        zIndex: 1
        // fontSize: ['1.5rem', '1.7rem', '2rem', '2.25rem', '2.5rem']
      },
      '.subtitle': {
        variant: 'text.subtitle',
        fontWeight: '300',
        zIndex: 1
      },
      '.text': {
        variant: 'text.text',
        padding: '1rem 0rem 1rem',
        zIndex: 1,
        fontSize: ['1.1rem'],
        mb: '2rem'
      },
      '.ctaLink': {
        variant: 'buttons.primary',
        margin: '0rem',
        zIndex: 1,
        ':hover': {
          backgroundColor: 'secondary',
          color: 'white'
        }
      }
    },

    '.contentBoxes': {
      alignItems: 'stretch',
      '> div:nth-of-type(odd)::before': {
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0%',
        left: '0%',
        backgroundColor: 'primary',
        zIndex: '-2',
        opacity: '0.9'
      },
      '> div:nth-of-type(even)::before': {
        content: "''",
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0%',
        left: '0%',
        backgroundColor: '#434339',
        zIndex: '-2',
        opacity: '0.6'
      },
      '> div:nth-of-type(odd)::after': {
        content: "''",
        width: '95%',
        height: '95%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        border: 'solid 1px white',
        zIndex: '-2',
        opacity: '0.5'
      },
      '> div:nth-of-type(even)::after': {
        content: "''",
        width: '95%',
        height: '95%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        border: 'solid 1px white',
        zIndex: '-2',
        opacity: '0.3'
      }
    }
  },

  homepageAdvisory: {
    margin: '10vh 0rem',
    padding: '0rem 5vw',
    '.section': {
      marginLeft: ['', '', '', '40%'],
      margin: '0rem',
      width: ['', '', '', '60%'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: '0rem 5%',
      textAlign: 'left',
      pt: '4rem'
    },
    '.title': {
      variant: 'text.title',
      position: ['', '', '', 'absolute'],
      border: 'none',
      borderTop: '1px solid',
      left: '2rem',
      top: '0rem',
      padding: ['2rem 0rem', '', '', '2rem'],
      width: ['', '', '', '40%'],
      order: '1'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      width: '100%',
      color: 'primary',
      order: '2'
    },
    '.text': {
      variant: 'text.text',
      marginBottom: '3rem',
      order: '3'
    },
    background: 'none'
  },

  homepageExperience: {
    margin: '10vh 0rem',
    padding: '0rem 5vw',
    height: '400px',
    '.section': {
      marginLeft: ['', '', '', '40%'],
      margin: '0rem',
      width: ['', '', '', '60%'],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: '0rem 5%',
      textAlign: 'left',
      pt: '1.5rem',
      zIndex: 1
    },
    '.title': {
      variant: 'text.title',
      position: ['', '', '', 'absolute'],
      border: 'none',
      borderTop: '1px solid',
      left: '2rem',
      top: '6rem',
      padding: ['2rem 0rem', '', '', '2rem'],
      width: ['', '', '', '40%'],

      order: '1'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      width: '100%',
      color: 'primary',
      order: '2'
    },
    '.text': {
      variant: 'text.text',
      marginBottom: '3rem',
      order: '3',
      display: 'flex',
      zIndex: '1'
    },
    '.gatsby-link': {
      order: '4'
    }
  },

  homepageAssignments: {
    variant: 'customVariants.homepageExperience'
  },

  homepageQuote: {
    overflow: 'visable',
    position: 'relative',
    minHeight: '75vh',
    '::before': {
      position: 'absolute',
      top: '-10vh',
      left: '-10vw',
      content: "''",
      width: 'calc(100% + 20vw)',
      zIndex: '-1',
      height: 'calc(50% + 20vh)',
      backgroundColor: '#d8dee3'
    },
    margin: '10vh 10vw',
    '.section': {
      maxWidth: '1000px',
      color: 'white',
      zIndex: 1
    },
    '.title': {
      variant: 'text.title',
      width: 'fit-content',
      // fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
      order: '1'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      width: '100%',
      order: '2',
      color: 'white',
      maxWidth: 'fit-content'
    },
    '.text': {
      variant: 'text.text',
      order: '3'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['10vh 1.5rem 10vh', '10vh 2rem 10vh', '10vh 3rem 10vh'],

    '.section': {
      alignItems: 'flex-start',
      textAlign: 'left',
      margin: '0rem'
    },
    '.title': {
      variant: 'text.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },
    '.text': {
      variant: 'text.text',
      textAlign: 'justify',
      lineHeight: '2.5'
    }
  },
  teamSection: {
    variant: 'customVariants.aboutSection',
    mt: '150px',
    pb: '1rem'
  },

  // ? =======================
  // ? =====  Services page  =====
  // ? =======================

  services1: {
    variant: 'customVariants.aboutSection'
  },

  // ? =======================
  // ? =====  Careers page  =====
  // ? =======================

  careers1: {
    variant: 'customVariants.aboutSection'
  },

  // ? =======================
  // ? =====  Experiences page  =====
  // ? =======================

  experiences1: {
    // mt: '150px',
    background: 'none',
    '.section': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      margin: '0rem',
      textAlign: 'left',
      '.title': {
        variant: 'text.title'
      },
      '.subtitle': {
        variant: 'text.subtitle'
      },
      '.text': {
        variant: 'text.text',
        ul: {
          columnCount: '2',
          columnGap: '20px'
        }
      }
    }
  },

  // ? ========================
  // ? ====  Partners page  ====
  // ? ========================

  ourPartners: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    background: 'none',
    maxWidth: 'unset',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '5vh 10vw',
    display: 'none',
    '.title': {
      variant: 'text.title',
      width: '100%',
      maxWidth: 'unset',
      display: 'none'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      maxWidth: 'unset',
      width: '100%',
      color: 'primary'
    },
    '.text': {
      variant: 'text.text',
      maxWidth: 'unset',
      width: '100%'
    },
    form: {
      mt: '2rem',
      maxWidth: '767px',
      '.inputField, .textField ': {
        fontWeight: '300',
        border: 'none',
        border: '1px solid',
        borderColor: 'black',
        borderRadius: '0px',
        padding: '1.5rem 0.5rem',
        color: 'text',
        fontFamily: 'heading',
        '::placeholder': {
          color: 'text'
        }
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        padding: '1rem 2rem',
        height: 'unset',
        width: 'fit-content',
        marginTop: '0.5rem',
        ':hover': {
          borderColor: 'dark',
          backgroundColor: 'dark'
        }
      }
    }
  },
  leadForm: {
    order: '4',
    textAlign: 'left',
    position: 'relative',
    alignItems: 'flex-start',
    mt: [12, '', '', '4rem'],
    '> *': {
      zIndex: '1'
    },
    '::before': {
      content: ['none', 'none', "''"],
      backgroundColor: 'rgb(3 27 45 / 85%)',
      width: '50%',
      height: '100%',
      position: 'absolute',
      top: '0%',
      right: '0%',
      zIndex: '0'
    },
    '.title': {
      variant: 'text.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '500px',
      width: '100%'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      maxWidth: '500px',
      width: '100%',
      opacity: '0.7',
      mb: '2rem'
    },
    '.text': {
      maxWidth: '500px',
      width: '100%',
      mb: '2rem'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    pl: ['', '', '52.5%'],
    form: {
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: '#72aee7',
      borderRadius: '0px',
      padding: '1.5rem 0.5rem',
      color: 'light',
      fontFamily: 'heading',
      '::placeholder': {
        color: 'white'
      }
    },
    textarea: {
      '::placeholder': {
        color: 'light',
        fontWeight: '300',
        fontFamily: 'heading'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      padding: '0.5rem 1rem',
      height: 'unset',
      width: 'auto',
      marginTop: '1.5rem',
      ':hover': {
        borderColor: 'dark',
        backgroundColor: 'dark'
      }
    }
  },

  // ? ===========================
  // ? ====  Assignment page  ====
  // ? ===========================

  assignmentIntro: {
    variant: 'customVariants.aboutSection'
  },

  assignment: {
    variant: 'customVariants.sideBySide1',
    '.contentBoxes': {
      justifyContent: 'flex-start'
    },
    'div.box': {
      width: ['', 'calc(50% - 2rem)', '', 'calc(33.3% - 2rem)'],
      borderRadius: '0px',
      img: {
        borderRadius: '0px',
        height: '325px',
        objectFit: 'cover',
        mb: '1rem'
      },
      '.title': {
        fontSize: ['1.5rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem']
      },
      '.subtitle': {
        fontSize: ['1.5rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem']
      }
    },
    padding: ['1rem', '', '1rem'],
    color: 'text'
  }
}

export default customVariants
