import noDataContainer from 'gatsby-theme-minimal/src/gatsby-plugin-theme-ui/Reuseables/NoDataContainer'
const variantName = 'teamMenu'

export default {
  [variantName]: {
    // ?============================
    // ?======  Containers =========
    // ?============================

    container: {
      padding: ['1rem 0.5rem 0.5rem', '1rem 1rem 1rem'],
      display: ['', '', '', 'flex'],
      maxWidth: '1200px',
      mx: 'auto'
    },

    // ?============================
    // ?========  Filters   ========
    // ?============================

    filters: {
      display: 'flex',
      padding: ['0rem', '', '', '0.5rem'],
      flexDirection: ['', '', '', 'column'],
      minWidth: '250px',
      borderRight: ['', '', '', '1px solid'],
      marginRight: ['', '', '', '1rem'],
      flexWrap: 'wrap'
    },
    filtersTitle: {
      width: '100%',
      padding: '0.5rem',
      marginBottom: '0.5rem'
    },

    filterLabel: {
      marginBottom: '0.25rem',
      padding: '0.25rem',
      fontSize: '0.8rem'
    },

    orderByContainer: {
      minWidth: '100px',
      margin: '0.5rem'
    },
    searchContainer: {
      flexGrow: ['1', '', '', '0'],
      margin: ['0.5rem'],
      svg: {
        position: 'absolute',
        right: '0.75rem',
        top: '50%',
        transform: 'translateY(-50%)'
      }
    },
    searchInputContainer: {
      position: 'relative'
    },

    sortByContainer: {
      margin: '0.5rem'
    },

    // ?============================
    // ?======  Article Boxes ======
    // ?============================

    blogsContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    boxesHeading: {
      textTransform: 'uppercase',
      textAlign: 'center',
      borderBottom: '2px solid',
      width: 'fit-content',
      padingBottom: '0.5rem',
      margin: ['0rem auto 2rem', '', '', '0rem auto 2rem']
    },

    boxesContainer: {
      display: 'flex',
      justifyContent: 'stretch',
      flexDirection: 'row',
      flexWrap: 'wrap',
      // flexFlow: 'row wrap-reverse',

      width: '100%'
    },
    box: {
      width: ['100%', '', 'calc(50% - 2rem)'],
      padding: '0rem',
      border: 'solid 1px #eee',
      margin: ['1rem 0rem', '0.5rem', '1rem 0.5rem'],
      position: 'relative',
      overflow: 'hidden',
      flexGrow: '1',
      a: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }
    },

    boxContentContainer: {
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      alignItems: 'flex-start'
    },

    // ?============================
    // ?======  Image Content =======
    // ?============================

    imageContainer: {
      width: ['100%', '', '40%', '40%'],
      maxWidth: '500px',
      position: 'relative',
      overflow: 'hidden',
      display: 'none'
    },
    imageFill: {
      paddingBottom: '100%'
    },
    image: {
      transition: 'all ease-in-out 0.5s',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },

    // ?============================
    // ?======  Text Content =======
    // ?============================
    textContent: {
      padding: ['1rem', '', '2rem'],
      fontSize: '1rem',
      transition: 'all ease-in-out 0.5s',
      backgroundColor: 'transparent',
      flexGrow: '1',
      display: 'flex',
      flexDirection: 'column',
      bottom: '0rem',
      width: ['100%', '', '60%', '60%'],

      '.bodyContainer': {
        '*': {
          textAlign: 'justify'
        }
      }
    },
    title: {
      fontSize: '2em',
      marginBottom: '1rem',
      lineHeight: '1.5'
    },
    subtitle: {
      fontSize: '1.25em',
      marginBottom: '1rem',
      opacity: '0.7',
      fontWeight: '400',
      lineHeight: '1.5'
    },
    text: {
      fontSize: '0.9em',
      margin: '0rem',
      flexGrow: '1',
      lineHeight: '1.25',
      display: 'none',
      '*': {
        textAlign: 'justify'
      }
    },
    bodyContainer: {
      '*': {
        margin: '0rem'
      }
    },

    timeContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      borderTop: '1px solid',
      opacity: '0.6',
      padding: '0.5rem 0rem 0rem',
      margin: 'auto 0rem 0rem',
      svg: {
        marginRight: '0.5rem'
      }
    },

    readingTime: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem',
      svg: {
        marginRight: '0.5rem'
      }
    },

    createdAt: {
      fontSize: '0.8em',
      margin: '0rem 1rem 0rem 0rem',
      padding: '0.5rem 0rem',
      svg: {
        marginRight: '0.5rem'
      }
    },

    // ?============================
    // ?========  Buttons  =========
    // ?============================

    ctaContainer: {
      a: {
        display: 'flex',
        variant: 'buttons.primary'
      }
    },

    // ?=============================
    // ?====  No Data Container  ====
    // ?=============================

    ...noDataContainer
  }
}
