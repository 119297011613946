export default {
  text: '#585858',
  text2: '#cecece',
  primary: '#003054',
  secondary: '#245378',
  background: '#ffffff',
  backgroundSecondary: '#003054',
  light: '#FFF',
  dark: '#555555'
}
